import SpaghettiAudio from 'spaghetti-audio'
import { GUI } from 'dat.gui'
;(() => {
  const settings = {
    withLocalStorage: true,
    damping: 0.1,
    debug: false,
    hitboxSize: 50,
    spaghettiColor: '#fcb699',
    spaghettiWidth: 10,
    totalPoints: 5,
    viscosity: 10,
  }

  const spaghetti = new SpaghettiAudio({ ...settings })
  const gui = new GUI()

  gui.close()

  const onChange = () => spaghetti.updateSettings({ ...settings })
  const clear = () => spaghetti.clearStrings()

  gui.addColor(settings, 'spaghettiColor').onChange(onChange)
  gui.add(settings, 'damping', 0.05, 0.4, 0.01).onFinishChange(onChange)
  gui.add(settings, 'spaghettiWidth', 5, 50, 1).onChange(onChange)
  gui.add(settings, 'viscosity', 5, 20, 1).onFinishChange(onChange)
  gui.add(settings, 'hitboxSize', 40, 80, 5).onChange(onChange)
  gui.add(settings, 'debug').onChange(onChange)
  gui.add({ clear }, 'clear')

  document.body.appendChild(spaghetti.canvas)
})()
